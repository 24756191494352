import React from "react";
import { useTranslation } from "react-i18next";
import Meta from "../components/Meta";
import SoonImage from "../assets/images/coming.svg";
import { useLocation } from "react-router-dom";
const SoonPage = () => {
  const { t } = useTranslation("header");
  const location = useLocation();
  const locationTitle =
    location.pathname === "/donate"
      ? t("Donation.donate")
      : location.pathname === "/reports"
      ? t("Reports.report")
      : "";
  return (
    <>
      <Meta title={locationTitle} />
      <div className="soon">
        {location.pathname === "/reports" ||
        location.pathname === "/cabinet/reports" ? null : (
          <>
            <h1 className="soon__header">{locationTitle}</h1>
            <img src={SoonImage} alt={t("soon")} loading="eager" />
          </>
        )}

        <span>{t("soon")}</span>
      </div>
    </>
  );
};

export default SoonPage;
