import customFetch from "../../utils/axios";

export const allregionsThunk = async (_, thunkAPI) => {
  try {
    const resp = await customFetch.get("/regions?take=50", {
      headers: {
        "x-localization": localStorage.getItem("i18nextLng"),
      },
    });
    return resp.data.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.message);
  }
};

export const commonRegionsThunk = async (_, thunkAPI) => {
  try {
    const resp = await customFetch.get("/common", {
      headers: {
        "x-localization": localStorage.getItem("i18nextLng"),
      },
    });
    return resp.data.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.message);
  }
};

export const donateRegionsThunk = async (_, thunkAPI) => {
  try {
    const resp = await customFetch.get("/regions/donate", {
      headers: {
        "x-localization": localStorage.getItem("i18nextLng"),
      },
    });
    return resp.data.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.message);
  }
};
