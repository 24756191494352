import axios from "axios";

const customFetch = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}`,
  headers: {
    "x-localization": localStorage.getItem("i18nextLng"),
    "Cache-Control": "no-cache, no-store, must-revalidate",
    Pragma: "no-cache",
    Expires: "0",
  },
});

export const checkForUnauthorizedResponse = (error, thunkAPI) => {
  if (error.response.status === 401) {
    return thunkAPI.rejectWithValue(401);
  }
  return thunkAPI.rejectWithValue(error.response.status);
};

export default customFetch;
