import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import ArrowPrevIcon from '../components/icons/ArrowPrevIcon'
import InfoSection from '../components/InfoSection'

const EmailVerifyAlready = () => {
  const {t}=useTranslation('verify')
  return (
    <>
    <div className='container error_page'>
      <h1 className='success__email__title'>{t('already.title')}</h1>
      <h3 className='success__email__desc'>{t('already.desc')}</h3>
      <Link to="/donate" className='error_page__button'><ArrowPrevIcon/> {t('back')}</Link>
    </div>
    <InfoSection/>
    </>
  )
}

export default EmailVerifyAlready