import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { allregionsThunk, commonRegionsThunk, donateRegionsThunk } from "./regionThunk";

export const getAllRegions = createAsyncThunk("regions", allregionsThunk);

export const getCommonRegion = createAsyncThunk("common", commonRegionsThunk);

export const getDonateRegions = createAsyncThunk("donateRegions", donateRegionsThunk);

const initialState = {
  isLoading: false,
  listLoading: false,
  error: null,
  regions: [],
  common: {},
  donateRegions: []
};

const regionSlice = createSlice({
  name: "regions",
  initialState,
  extraReducers: {
    [getAllRegions.pending]: (state) => {
      state.isLoading = true;
    },
    [getAllRegions.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.regions = payload;
    },
    [getAllRegions.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.error = payload;
    },
    [getCommonRegion.pending]: (state) => {
      state.listLoading = true;
    },
    [getCommonRegion.fulfilled]: (state, { payload }) => {
      state.listLoading = false;
      state.common = payload;
    },
    [getCommonRegion.rejected]: (state, { payload }) => {
      state.listLoading = false;
      state.error = payload;
    },
    [getDonateRegions.pending]: (state) => {
      state.listLoading = true;
    },
    [getDonateRegions.fulfilled]: (state, { payload }) => {
      state.listLoading = false;
      state.donateRegions = payload;
    },
    [getDonateRegions.rejected]: (state, { payload }) => {
      state.listLoading = false;
      state.error = payload;
    },
  },
});

export default regionSlice.reducer;
